import React from "react";

const AboutSection: React.FC = () => {
  return (
    <section className="container-fluid about" id="about">
      <div className="container about-intro row flex justify-between align-items-center mx-auto">
        <div className="col-md-6 col-12">
          <p className="h2 fw-bold intro1 typing-animation">I am Hassan Younus</p>
          <p className="h1 fw-bold intro2 typing-animation">Software Engineer</p>
          <br />
          <p className="h5 text-secondary">
            Seasoned JavaScript Full Stack Engineer with 10+ years of experience
            in React.js, Node.js, Azure, and Google Cloud, delivering
            high-performance, scalable web applications. Expert in optimizing
            systems, enhancing user experience, and reducing costs through Agile
            methodologies.
          </p>
          <br />
          <div className="d-flex flex-row flex-sm-wrap gap-3">
            <a href="/Assets/PhD Hassan Younus.pdf" download="Hassan's_CV.pdf">
              <button className="rounded-pill px-4 py-3 fw-bold cv-btn">
                Download CV <i className="fa-solid fa-download"></i>
              </button>
            </a>

            <div className="align-items-center my-auto d-flex justify-content-between gap-2">
            <a
                className="mx-auto"
                href="https://stackoverflow.com/users/27438808/hassan-younus"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-stack-overflow social-icon rounded-circle p-2"></i>
              </a>
              <a
                className="mx-auto"
                href="https://www.linkedin.com/in/hassan-younus-49a3b22ba/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin-in social-icon rounded-circle p-2"></i>
              </a>
              <a
                className="mx-auto"
                href="https://github.com/hssnyounus"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-github social-icon rounded-circle p-2"></i>
              </a>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="col-md-6 col-12 d-md-block">
          <div className="img-box">
            <img
              src="/Assets/hassan-younus.jpg"
              className="border-col rounded-5"
              alt="Hassan Younus"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="d-flex flex-row align-items-center gap-3 col-6 col-md-6 col-lg-3">
            <p className="h1 text-white experience">10</p>
            <div className="text-white h5 exp">
              Years of
              <br />
              Experience
            </div>
          </div>
          <div className="d-flex flex-row align-items-center gap-3 col-6 col-md-6 col-lg-3">
            <p className="h1 text-white experience">50+</p>
            <div className="text-white h5 exp">
              Projects
              <br />
              Completed
            </div>
          </div>
          <div className="d-flex flex-row align-items-center gap-3 col-6 col-md-6 col-lg-3">
            <p className="h1 text-white experience">1.5K</p>
            <div className="text-white h5 exp">
              Happy
              <br />
              Clients
            </div>
          </div>
          <div className="d-flex flex-row align-items-center gap-3 col-6 col-md-6 col-lg-3">
            <p className="h1 text-white experience">15+</p>
            <div className="text-white h5 exp">Skills</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
