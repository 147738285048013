import React, { useState, useRef } from "react";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const inputElement = useRef<HTMLButtonElement>(null);

  const toggleMenu = () => {
    if (window.matchMedia("(max-width: 1024px)").matches) { 
      setIsOpen(!isOpen);
      if (inputElement.current) {
        inputElement.current.style.display = "none";
      }
    }
  };

  const handleMenu = () => {
    if (window.matchMedia("(max-width: 1024px)").matches) {
      setIsOpen(false);
      if (inputElement.current) {
        inputElement.current.style.display = "block";
      }
    }
  };

  return (
    <nav className="nav-shadow sticky top-0 py-4 z-50">
      <div className="container mx-auto flex items-center justify-between">
        <button
          ref={inputElement}
          className="block lg:hidden text-white focus:outline-none tog-col"
          type="button"
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon">&#9776;</span>
        </button>

        <a
          className="hidden lg:block text-white text-lg mail"
          href="mailto:hssnyounus@gmail.com"
        >
          hssnyounus@gmail.com
        </a>

        <div
          className={`${
            isOpen ? "block" : "hidden"
          } lg:flex flex-col lg:flex-row w-full lg:w-auto lg:items-center`}
        >
          <ul className="flex flex-col lg:flex-row  lg:space-x-6 items-start gap-4 pr-6">
            <li>
              <a
                onClick={handleMenu}
                className="nav-link text-white text-lg dropdown-anim"
                href="#"
              >
                Home
              </a>
            </li>
            <li>
              <a
                onClick={handleMenu}
                className="nav-link text-white text-lg dropdown-anim"
                href="#about"
              >
                About
              </a>
            </li>
            <li>
              <a
                onClick={handleMenu}
                className="nav-link text-white text-lg dropdown-anim"
                href="#service"
              >
                Services
              </a>
            </li>
            <li>
              <a
                onClick={handleMenu}
                className="nav-link text-white text-lg dropdown-anim"
                href="#projects"
              >
                Portfolios
              </a>
            </li>
            <li>
              <a
                onClick={handleMenu}
                className="nav-link text-white text-lg dropdown-anim"
                href="#experience"
              >
                Experience
              </a>
            </li>
            <li>
              <a
                onClick={handleMenu}
                className="nav-link text-white text-lg dropdown-anim"
                href="#skills"
              >
                Skills
              </a>
            </li>
            <li>
              <a
                onClick={handleMenu}
                className="nav-link text-white text-lg dropdown-anim"
                href="#reviews"
              >
                Reviews
              </a>
            </li>
            <li>
              <a
                onClick={handleMenu}
                className="nav-link text-white text-lg dropdown-anim"
                href="#blogs"
              >
                Catalogue
              </a>
            </li>
          </ul>
          <div className="lg:flex lg:items-center mt-3 flex-row sm:flex sm:flex-col flex justify-between">
            <div>
              <a
                onClick={handleMenu}
                className="block lg:hidden text-white text-lg mail mt-4 text-center"
                href="mailto:hssnyounus@gmail.com"
              >
                hssnyounus@gmail.com
              </a>
            </div>
            <a
              href="https://www.upwork.com/freelancers/0122c47a8c851806e6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                onClick={handleMenu}
                className="btn hireme px-5 py-3 rounded-full transition-all"
                type="button"
              >
                Hire me
              </button>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
