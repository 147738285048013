import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination"; // Import pagination styles
import { Pagination } from "swiper/modules";

// Dummy review data
const reviews = [
  {
    quote:
      "PnE Technologies is a highly skilled and reliable software company that delivers innovative, scalable solutions with excellent communication and attention to detail.",
    name: "M Abdullah",
    position: "FrontEnd Developer",
    testImg1: "/Assets/pne technologies.png",
    testImg2: "/Assets/abdullah.png",
  },
  {
    quote:
      "PnE Technologies provides exceptional software services, combining expertise, innovation, and professionalism to deliver top-quality solutions on time.",
    name: "M Naveed",
    position: "BackEnd Developer",
    testImg1: "/Assets/pne technologies.png",
    testImg2: "/Assets/naveed.png",
  },
  {
    quote:
      "PnE Technologies delivers superior software solutions, seamlessly integrating expertise, innovation, and professionalism to ensure timely, top-tier results.",
    name: "M Saad",
    position: "BackEnd Developer",
    testImg1: "/Assets/pne technologies.png",
    testImg2: "/Assets/saad.jpg",
  },
  {
    quote:
      "PnE Technologies offers outstanding software services, blending expertise, innovation, and professionalism to deliver high-quality solutions with precision and timeliness.",
    name: "Adnan bin Akram",
    position: "Senior Software Developer",
    testImg1: "/Assets/pne technologies.png",
    testImg2: "/Assets/saad.jpg",
  },
];

const ReviewsSection: React.FC = () => {
  return (
    <section className="container-fluid reviews" id="reviews">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <p className="h1 fw-bold">My Client's Stories</p>
            <p className="h5 text-white">
              Empowering people in a new digital journey with my Super Services.
            </p>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            {/* Swiper Slider */}
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              loop={true}
              pagination={{ clickable: true }}
              breakpoints={{
                576: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {reviews.map((review, index) => (
                <SwiperSlide key={index} className="swiperSlide rounded-4">
                  <div style={{height:"auto",width:"100%"}}>
                    <div className="testimg">
                      <img src={review.testImg1} className="testimg1" alt="" />
                      <img src={review.testImg2} className="testimg2" alt="" />
                    </div>
                    <i className="fa-solid fa-right-left"></i>
                    <br />
                    <p className="h6 text-secondary">{review.quote}</p>
                    <p className="text-white fw-bold">{review.name}</p>
                    <p className="h6 text-secondary">{review.position}</p>
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper-pagination"></div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;
