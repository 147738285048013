/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container">

        <nav className="mb-4">
          <a href="#about" className="footer-link">About</a>
          <a href="#service" className="footer-link">Services</a>
          <a href="#projects" className="footer-link">Portfolios</a>
          <a href="#experience" className="footer-link">Experience</a>
          <a href="#reviews" className="footer-link">Reviews</a>
          <a href="#blogs" className="footer-link">Catalogue</a>
          
        </nav>

        <div className="footer-bottom">
          <p>© Developed by <a href="#">Hassan Younus</a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
