import React from 'react';

const services = [
  {

    title: 'WEB APPLICATION',
    description: 'Custom Web Applicatin to built to elevate your business',
  },
  {

    title: 'MOBILE APPLICATION',
    description: 'Building custom mobile apps to help grow your business and improve user experience',
  },
  {

    title: 'DEVOPS',
    description: 'Streamlined DevOps Solutions to accelerate your Development',
  },
  {

    title: 'AI/ML DEVELOPMENT',
    description: 'AI/ML Solutions to transform your Business',
  },
  {

    title: 'UX/UI DESIGNING',
    description: 'Exceptional UX/UI Design to enhance User Engagement',
  },
  {

    title: 'SAAS PRODUCT DEVELOPMENT',
    description: 'Developing tailored SaaS products to help your business scale and meet customer needs effortlessly',
  },
];

const ServicesSection: React.FC = () => {
  return (
    <section className="container-fluid services" id="service">
      <div className="container">
        <div className="text-center ser-intro">
          <p className="h1 text-center fw-bold ser-head">My Quality Services</p>
          <p className="h5 text-white w-[80%] mx-auto">
          Offering tailored web development services, from responsive design to full-stack solutions, ensuring performance and user-centric experiences.
          </p>
        </div>
        <div className="container service-section">
          <div className="row">
            {services.map((service, index) => (
              <div className="col-md-6 col-lg-4 mb-4" key={index}>
                <div className="service-item p-4">
                  <h5 className="service-title">{service.title}</h5>
                  <p className="description">{service.description}</p>
                  <span className="arrow">&rarr;</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;