import React from "react";

const SkillsSection: React.FC = () => {
  const skills = [
    { name: "MERN", imgSrc: "/Assets/mern.png", percentage: "95%" },
    { name: "MEAN", imgSrc: "/Assets/mean.jpeg", percentage: "95%" },
    { name: "Java", imgSrc: "/Assets/java.webp", percentage: "90%" },
    {
      name: "Spring Boot",
      imgSrc: "/Assets/spring boot.png",
      percentage: "90%",
    },
    { name: "Git", imgSrc: "/Assets/git.png", percentage: "90%" },
    { name: "Next.js", imgSrc: "/Assets/next.png", percentage: "95%" },
    { name: "Firebase", imgSrc: "/Assets/firebase.png", percentage: "90%" },
    { name: "Jest", imgSrc: "/Assets/jest.png", percentage: "90%" },
    { name: "WebSockets", imgSrc: "/Assets/socket.png", percentage: "90%" },
    { name: "MongoDB", imgSrc: "/Assets/mongo.png", percentage: "95%" },
    { name: "DevOps", imgSrc: "/Assets/devops.webp", percentage: "90%" },
    { name: "PostgreSQL", imgSrc: "/Assets/postgres.png", percentage: "95%" },
    { name: "Azure", imgSrc: "/Assets/azure.png", percentage: "90%" },
    { name: "Figma", imgSrc: "/Assets/figma.jpg", percentage: "95%" },
    { name: "ML/AI", imgSrc: "/Assets/ml.jpeg", percentage: "90%" },
    { name: "React Native", imgSrc: "/Assets/native.webp", percentage: "90%" },
  ];

  return (
    <section className="container-fluid skills" id="skills">
      <div className="container">
        <div className="text-center skill-intro">
          <p className="h1 text-center fw-bold skill-head">My Skills</p>
          <p className="h5 text-white w-[80%] mx-auto">
            Highlighting my technical expertise across various programming
            languages, frameworks, and tools to build innovative solutions.
          </p>
        </div>

        <div className="container row justify-around">
          {skills.map((skill, index) => (
            <div
              key={index}
              className="col-lg-2 col-md-4 col-sm-6 gap-3 skill-box d-flex flex-column align-items-center justify-content-center"
            >
              <div className="skill-content py-5 rounded-4 text-secondary">
                <div>
                  <img src={skill.imgSrc} alt={skill.name} />
                </div>
                <p className="h2">{skill.percentage}</p>
              </div>
              <p className="h6">{skill.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
