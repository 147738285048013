import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

interface Project {
  imgSrc: string;
  name: string;
  desc: string;
  category: string;
}

interface TabProps {
  children: React.ReactNode;
  setPosition: (position: {
    left: number;
    width: number;
    opacity: number;
  }) => void;
  setActiveFilter: (filter: string) => void;
  activeFilter: string;
}

const ProjectsSection: React.FC = () => {
  const [activeFilter, setActiveFilter] = useState<string>("All");

  

  const projDesc: Project[] = [
    {
      imgSrc: "/Assets/proj1.png",
      name: "BUSD",
      desc: "A blockchain-based platform for BUSD token airdrop and pre-sale, built with an ERC20 smart contract and web3.js. The platform is fully responsive for web and mobile, with a robust backend using Node.js, Express.js, MongoDB, and Mongoose ORM.",
      category: "Blockchain",
    },
    {
      imgSrc: "/Assets/proj2.png",
      name: "Dapp Cryptoindexpool",
      desc: "A decentralized application (dApp) built for managing crypto index pools using Solidity, ethers.js, and Next.js. It integrates web3 functionalities with a user-friendly interface, utilizing Prisma for database management and MySQL for data storage.",
      category: "Blockchain",
    },
    {
      imgSrc: "/Assets/proj3.png",
      name: "Ronin",
      desc: "A full-stack NFT marketplace platform enabling users to mint, buy, and sell NFTs. It integrates APIs for payment processing via Stripe, real-time updates using Firebase, and a responsive React UI powered by Redux, deployed with NGINX and Node.js.",
      category: "Blockchain",
    },
    {
      imgSrc: "/Assets/proj4.png",
      name: "Cloud NFT",
      desc: "A full-stack web application for minting, managing, and trading NFTs on decentralized cloud infrastructure. Features Dockerized microservices, seamless API integration, and a responsive React UI for an enhanced user experience.",
      category: "Blockchain",
    },
    {
      imgSrc: "/Assets/proj5.png",
      name: "HoloFair",
      desc: "A responsive gateway for accessing and managing 3D virtual event platforms. This portal allows users to create custom events, manage dashboards, and edit venues with ease, offering seamless interaction and smooth navigation.",
      category: "Web",
    },
    {
      imgSrc: "/Assets/proj6.png",
      name: "Joblinxs",
      desc: "A full-stack job search platform designed to connect job seekers with employers. It features API integration with Stripe for payments, Firebase for real-time data, and a React-based UI enhanced by Redux for state management, deployed using NGINX and Node.js.",
      category: "Web",
    },
    {
      imgSrc: "/Assets/finance.jpg",
      name: "Finance",
      desc: "A modern finance management web app developed using Next.js, Drizzle ORM, Hono.js, and Zod for seamless data management and validation. It features an intuitive UI built with Shadcn UI, leveraging NeonDB and PostgreSQL for robust backend support.",
      category: "Web",
    },
    {
      imgSrc: "/Assets/ponder.jpg",
      name: "Ponder Insight",
      desc: "A dynamic blogging platform developed using the MERN stack, Firebase for real-time data management, and styled with Tailwind CSS and Flowbite for a responsive, modern design. It offers an intuitive user experience for creating and sharing insightful blog posts.",
      category: "Web",
    },
    {
      imgSrc: "/Assets/chat.jpg",
      name: "Chat App",
      desc: "A real-time chat app developed using the MERN stack, Socket.IO for live messaging, and Redux with RTK Query for state management. The responsive UI is designed with Material UI for an enhanced user experience.",
      category: "Web",
    },
    {
      imgSrc: "/Assets/todo.jpg",
      name: "TODO",
      desc: "A mobile application for task management developed using React Native for a seamless user experience, with Node.js and Express.js for backend services. It leverages MongoDB for efficient data storage and retrieval.",
      category: "App",
    },
    {
      imgSrc: "/Assets/pondermobile.jpg",
      name: "Ponder Insight",
      desc: "A mobile blogging platform developed using React Native, featuring Kinde for authentication and React Native Navigation for smooth user experience. It integrates with Highgraph CMS for efficient content management and retrieval.",
      category: "App",
    },
  ];

  const filterProjects = (): Project[] => {
    if (activeFilter === "All") {
      return projDesc;
    }
    return projDesc.filter((proj) => proj.category === activeFilter);
  };

  return (
    <section className="container-fluid projects" id="projects">
      <div className="container">
        <div className="text-center proj-intro">
          <p className="h1 text-center fw-bold proj-head">My Recent Work</p>
          <p className="h5 text-white w-[80%] mx-auto">
            Showcasing a collection of recent projects, highlighting innovative
            web and cloud-based solutions built with cutting-edge technology.
          </p>
        </div>
        <div className="container proj-section">
          <div className="proj-filter-container">
            <SlideTabs
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
            />
          </div>
          <div className="row align-items-center d-flex justify-content-between gap-2">
            {filterProjects().map((proj: Project, index: number) => (
              <div className="col-lg-3 col-md-6 col-sm-12 proj-box" key={index}>
                <div className="proj-img">
                  <img src={proj.imgSrc} alt={proj.name} />
                  <div className="proj-content justify-content-between py-3 px-3">
                    <div className="align-items-center">
                      <p className="h3 fw-bold">{proj.name}</p>
                      <p className="h6 text-white">{proj.desc}</p>
                    </div>
                    <span className="arrow"></span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const SlideTabs: React.FC<{
  setActiveFilter: (filter: string) => void;
  activeFilter: string;
}> = ({ setActiveFilter, activeFilter }) => {
  const [position, setPosition] = useState<{
    left: number;
    width: number;
    opacity: number;
  }>({
    left: 4,
    width: 59.6094,
    opacity: 1,
  });

  return (
    <ul className="relative mx-auto flex w-fit rounded-full bg-black p-1">
      <Tab
        setPosition={setPosition}
        setActiveFilter={setActiveFilter}
        activeFilter={activeFilter}
      >
        All
      </Tab>
      <Tab
        setPosition={setPosition}
        setActiveFilter={setActiveFilter}
        activeFilter={activeFilter}
      >
        Blockchain
      </Tab>
      <Tab
        setPosition={setPosition}
        setActiveFilter={setActiveFilter}
        activeFilter={activeFilter}
      >
        Web
      </Tab>
      <Tab
        setPosition={setPosition}
        setActiveFilter={setActiveFilter}
        activeFilter={activeFilter}
      >
        App
      </Tab>

      <Cursor position={position} />
    </ul>
  );
};

const Tab: React.FC<TabProps> = ({
  children,
  setPosition,
  setActiveFilter,
  activeFilter,
}) => {
  const ref = useRef<HTMLLIElement | null>(null);

  const handleClick = () => {
    if (!ref.current) return;

    const { width } = ref.current.getBoundingClientRect();

    setPosition({
      left: ref.current.offsetLeft,
      width,
      opacity: 1,
    });
    console.log(children);

    setActiveFilter(children as string);
  };
  useEffect(() => {
    setActiveFilter("All");
    console.log(children);
    
  }, []);
  return (
    <li
      ref={ref}
      onClick={handleClick}
      className={`relative z-10 block cursor-pointer px-3 py-1.5 text-xs uppercase text-white mix-blend-difference md:px-5 md:py-3 md:text-base ${
        activeFilter === children ? "font-bold" : ""
      }`}
    >
      {children}
    </li>
  );
};

const Cursor: React.FC<{
  position: { left: number; width: number; opacity: number };
}> = ({ position }) => {
  return (
    <motion.li
      animate={position}
      className="absolute z-0 h-7 rounded-full anim-col md:h-12"
    />
  );
};

export default ProjectsSection;
