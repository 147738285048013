import React from 'react';
import '../App.css'

const ExperienceSection: React.FC = () => {
  const Experience = [
    { year:'10 August 2019 - Current', role:'JavaScript FUll Stack Engineer',company:'PnE Technologies' ,city:' New York, United States'},
    { year:'5 July 2016 - 10 July 2019', role:'FrontEnd Engineer',company:'PnE Technologies' ,city:'Islamabad, Pakistan'},
  ];
  const Education = [
    { year:'5 July 2017 - 15 October 2020', role:'Doctorate of Computer Science',institute:'LUMS' ,city:'Lahore, Pakistan '},
    { year:'7 July 2015 - 10 June 2017', role:'Master of Computer Science',institute:'LUMS' ,city:'Lahore, Pakistan '},
    
  ];
  return (
    <section className="container-fluid experiences" id="experience">
      <div className="container">
        <div className="container row justify-content-between gap-5 align-items-center">
          <div className="row col-md-6 col-sm-12 gap-5">
            <div className="col-12 d-flex flex-row gap-3 ex-head align-items-center">
              <i className="fs-1 fa-solid fa-certificate"></i>
              <p className="h1 fw-bolder text-white">My Experience</p>
            </div>

            {Experience.map((exp, index) => (
              <div key={index} className="col-12 ex-box align-items-center">
                <p className="h4 fw-bold">{exp.year}</p>
                <p className="h2 text-white fw-bold">{exp.role}</p>
                <p className="h3 fw-bold">{exp.company}</p>
                <p className="h4 text-white">{exp.city}</p>
              </div>
            ))}
          </div>

          <div className="row col-md-6 col-sm-12 gap-5">
            <div className="col-12 align-items-center ex-head d-flex flex-row gap-3">
              <i className="fs-1 fa-solid fa-graduation-cap"></i>
              <p className="h1 fw-bolder text-white">My Education</p>
            </div>

            {Education.map((edu, index) => (
              <div key={index} className="col-12 ex-box align-items-center">
                <p className="h4 fw-bold">{edu.year}</p>
                <p className="h2 text-white fw-bold">{edu.role}</p>
                <p className="h3 fw-bold">{edu.institute}</p>
                <p className="h4 text-white">{edu.city}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExperienceSection;
