import React from "react";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";

import AboutSection from "./components/AboutSection";
import Footer from "./components/Footer";
import ExperienceSection from "./components/ExperienceSection";
import Navbar from "./components/Navbar";
import ProjectsSection from "./components/ProjectsSection";
import ReviewsSection from "./components/ReviewsSection";
import ServicesSection from "./components/ServicesSection";
import SkillsSection from "./components/SkillsSection";
import CatalogueSection from "./components/CatalogueSection";

const App: React.FC = () => {
  return (

    <div className="App">
      <Navbar/>
      <AboutSection />
      <ServicesSection />
      <ProjectsSection />
      <ExperienceSection />
      <SkillsSection />
      <ReviewsSection />
      <CatalogueSection/>
      <Footer />
    </div>
  );
};

export default App;
