import React from "react";

interface Catalogue {
  image: string;
  category: string;
  desc: string;
  title: string;
}

const Catalogues: Catalogue[] = [
  {
    image: "/Assets/cat1.jpg",
    category: "7 days delivery",
    desc: "You will get Full Stack SaaS application development with AI.",
    title: "SaaS",
  },
  {
    image: "/Assets/cat2.jpg",
    category: "1 day delivery",
    desc: "You will receive a robust Full stack Web application designed for optimal performance, scalability, and a seamless user experience.",
    title: "Web Application",
  },
  {
    image: "/Assets/cat3.jpg",
    category: "4 day delivery",
    desc: "You will receive an intuitive Admin Dashboard for seamless management of users, data, and platform operations.",
    title: "Admin Dashboard",
  },
  {
    image: "/Assets/cat4.jpg",
    category: "2 day delivery",
    desc: "You will get Investigation fix all bug and add extra features.",
    title: "Bug Fixing",
  },
];

const CatalogueSection: React.FC = () => {
  return (
    <section className="container-fluid blogs" id="blogs">
      <div className="container">
        <div className="text-center blog-intro">
          <p className="h1 text-center fw-bold blog-head">My Catalogue</p>
          <p className="h5 text-white">
            Get started working with Hassan quickly with these predefined projects.
          </p>
        </div>
        <div className="container blog-section">
          <div className="row d-flex justify-content-between align-items-center">
            {Catalogues.map((catalogue, index) => (
              <div key={index} className="col-lg-3 col-md-6 col-sm-12 blog-box mx-auto mb-4">
                <div className="blog-image-container">
                  <img src={catalogue.image} alt={catalogue.title} className="blog-image" />
                </div>
                <p className="category text-white">
                  <i className="fa-regular fa-clock"></i> {catalogue.category}
                </p>
                <div className="blog-content p-3">
                  <div className="align-items-center">
                    <p className="h6">{catalogue.desc}</p>
                    <p className="h3 text-white">{catalogue.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr />
    </section>
  );
};

export default CatalogueSection;
